import { useEffect, useState } from "react"

export default function useTouch() {
  const [isTouch, setIsTouch] = useState(false)

  useEffect(() => {
    var currentIsTouch = false //var to indicate current input type (is touch versus no touch)
    var isTouchTimer 
     
    function addTouch(e){
        // console.log("addTouch-event ist fired")
        clearTimeout(isTouchTimer)
        currentIsTouch = true
        if (!isTouch){ //if isTouch is false set state to true
            setIsTouch(true)
        }
        isTouchTimer = setTimeout(function(){currentIsTouch = false}, 500) //maintain "istouch" state for 500ms so removetouchclass doesn't get fired immediately following a touch event
    }
     
    function removeTouch(e){
        // console.log("removeTouch-event ist fired")
        if (!currentIsTouch && isTouch === true){ //if isTouch is true set state to false
            currentIsTouch = false
            setIsTouch(false)
        }
    }
     
    document.addEventListener('touchstart', addTouch, false) //this event only gets called when input type is touch
    document.addEventListener('mouseover', removeTouch, false) //this event gets called when input type is everything from touch to mouse/ trackpad

    return () => {
      document.removeEventListener('touchstart', addTouch)
      document.removeEventListener('mouseover', removeTouch)
    }
    
  }, [isTouch]);

  return isTouch;
}