import React from "react"
import Artist from "../components/Artist"

export default function ArtistList(props) {

  return (
    <div className="gridContainer">
      {props.data.map(({ node }, index) => (
        <Artist
          index={index}
          key={node.id}
          firstName={node.firstName}
          lastName={node.lastName}
          slug={node.slug.current}
          preview={props.preview}
          location={props.location}
          onClick={() => props.onClick()}
          onMouseEnter={() => props.onMouseEnter(index)}
          onMouseLeave={() => props.onMouseLeave(index)}
        />
      ))}
    </div>
  )
}
