import React from "react"

export default function Accordion(props) {

  // function handleScroll(e) {
  //   const scrollTop = e.target.scrollTop
  //   console.log(scrollTop)
  // }

  return (
    <div className="accordion__section" 
    >
      <button
        className={`accordion__button ${props.state}`}
        //onMouseEnter={() => props.onMouseEnter()}
        //onMouseLeave={() => props.onMouseLeave()}
        onClick={() => props.onClick()}
      >
        <div>
        <h2 className="accordion__title__letter">{props.titleTop}</h2>
        </div>
        <div>
        {/* <p className={`accordion__toggle__${setHover}`}> */}
        <p className="accordion__toggle">
          {props.state === "active" ? "" : "+"}
        </p>
        </div>
        {/* <p className="accordion__title">{props.titleBottom}</p> */}
      </button>      
      <div id={`scrollContainer_${props.index}`} className={`accordion__content__wrapper__${props.state}`} onScroll={(e) => props.onScroll(e)}>
        {props.children}       
      </div>    
    </div>
  )
}
