import React from "react"
import { Media } from "../Media"
import Img from "gatsby-image"

export default function PreviewWrapper(props) {

  const prevStyle = {
       left: props.xPos + "px", 
       top: props.yPos + "px",
     };

  return (
      <div>
        <Media at="sm">
        <ImgFluid data={props.data} preview={props.preview}/>
        </Media>
        <Media greaterThan="sm">
        <ImgFixed data={props.data} preview={props.preview} prevstyle={prevStyle}/>
        </Media>
    </div>
  )
}

function ImgFixed(props) {
  return (
    <div className="previewImage" style={props.prevstyle}>
    {props.data.map(({ node }, index) => (
      <Img
      fixed={node.previewImage.asset.localFile.childImageSharp.fixed}
      style={{ display: "inherit" }}
      index={index}
      key={node.id}
      className={props.preview[index] ? "previewActive" : "previewInactive"}
      loading="eager"
      // fadeIn="false"
      />
    ))}
    </div>
  )
}

function ImgFluid(props) {
  return (
    <div className="previewImage">
    {props.data.map(({ node }, index) => (
    <Img
    fluid={node.previewImage.asset.fluid}
    style= {{height: "100%", width: "100%"}}
    imgStyle={{objectFit: "contain", margin: "0"}}
    index={index}
    key={node.id}
    className={props.preview[index] ? "previewActive" : "previewInactive"}
    loading="eager"
    />
    ))}
    </div>
  )
}