import "./src/styles/global.scss"
import React from "react"
import Layout from "./src/components/Layout"
import { MediaContextProvider } from "./src/Media"


export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
)

export const wrapRootElement = ({ element }) => (
  <MediaContextProvider>{element}</MediaContextProvider>
)

export const onPreRouteUpdate = () => {
  // document.getElementById("scrollContainer").scrollTop = 0
  return false
}

export const shouldUpdateScroll = () => {
  document.getElementById("scrollContainer_1").scrollTop = 0
  // return false
}