import React from "react"

export default function Info(props) {

    return (
        <div className="accordion__content">
            <h2 className='artist__name'>Kunst</h2>
            
            <section>
                <h3>»MFA 2020«<span> ¶ </span></h3><p>Im Rahmen einer Ausstellung, einer Publikation und dieser Internetseite sind die Abschlussarbeiten der Künstler*innen zu sehen, die die Muthesius Kunsthochschule als Absolvent*innen die mit dem Titel »Master of Fine Arts« verlassen.</p>
                <p><span> → </span><a rel="noreferrer" href="https://muthesius-kunsthochschule.de/" target="_blank">https://muthesius-kunsthochschule.de/</a><span> ← </span></p>
            </section>
            <section>
            <p>Ziel des Studiums Freie Kunst ist die Entwicklung einer eigenständigen künstlerischen Identität. Das Studium soll qualifizieren, eigene künstlerische Ideen umzusetzen, die Qualität eigener Arbeiten einzuschätzen, sie in einen theoretisch relevanten Kontext zu stellen und sie angemessen zu präsentieren.</p>
                <p><span> ¶ </span>Die im Rahmen des Bachelorstudienganges grundlegenden praktischen, theoretischen und künstlerisch‐gestalterischen Fähigkeiten werden im Master Studium verfeinert, ausgebaut und intensiver befragt.</p>
                <p><span> ¶ </span>In Projekten außerhalb der Hochschule konkretisieren sich gestalterische Vorgehensweise im Hinblick auf mögliche Darstellungsformen. Das Studium soll befähigen, eigene Positionen und Reaktionen zu formulieren. Begleitend zu der fachlichen Ausbildung werden die Studierenden mit dem Betriebssystem »Kunst« vertraut gemacht – auch in Bezug auf gesellschaftliche Relevanz.</p>
                <p><span> ¶ </span>Konzipiert, gestaltet und kuratorisch begleitet wurde das Projekt »MFA 2020« von der Künstlerin Anne Steinhagen und dem Gestalter Björn Schmidt.</p>
            </section>
            <section>
                <p>Lehrende des Fachbereichs sind</p>
                <ol>
                    <li><span> → </span><span className='caps'>PROF.IN DR. KERSTIN ABRAHAM</span> (Freie Kunst und Keramik)</li>
                    <li><span> → </span><span className='caps'>PROF. ARNOLD DREYBLATT</span> (Medienkunst)</li>
                    <li><span> → </span><span className='caps'>PROF.IN ALMUT LINDE</span> (Interdisziplinäre künstlerische Praxis)</li>
                    <li><span> → </span><span className='caps'>PROF.IN ANTJE MAJEWSKI</span> (Malerei)</li>
                    <li><span> → </span><span className='caps'>PROF. PIOTR NATHAN</span> (Freie Zeichnung und Druckgrafik)</li>
                    <li><span> → </span><span className='caps'>PROF. ELISABETH WAGNER</span> (Bildhauerei)</li>
                    <li><span> → </span><span className='caps'>PROF. BKH GUTMANN</span> (Basisklasse)</li>
                    <li><span> → </span><span className='caps'>PROF. STEPHAN SACHS</span> (Film / Time-based Media) Das Lehrgebiet Film / Time-based Media ist interdisziplinär und dem Zentrum für Medien der Muthesius Kunsthochschule zugeordnet.<span> ¶ </span>Das Lehrgebiet bietet ebenfalls einen Masterschwerpunkt an.</li>
                </ol>
            </section>
            <section className='imprint'>
                <h3>Impressum</h3>
                <p>Muthesius Kunsthochschule<br />Legienstraße 35 24103 Kiel<br />Telefon 0176 63 17 01 55</p>
                <p>Juristisch Vertretungsberechtigter:<br />Dr. Arne Zerbst, Präsident der Muthesius Kunsthochschule<br /></p>
                <p>Verantwortliche für die redaktionellen Inhalte ist Anne Steinhagen, <a href='mailto:mfa2020@muthesius.de'>mfa2020@muthesius.de</a>.<span> ¶ </span>Für die Inhalte der Seiten der Absolvent*innen sind die jeweiligen Personen bzw. die von Ihnen Betrauten verantwortlich.<span> ¶ </span>Unsere Website und die darin enthaltenen Informationen wurden von uns mit größtmöglicher Sorgfalt erstellt. Trotzdem können wir für die Richtigkeit und Vollständigkeit des Inhalts keine Gewähr übernehmen. In keinem Fall wird für Schäden, die sich aus der Verwendung der abgerufenen Informationen ergeben, eine Haftung übernommen. Die Muthesius Kunsthochschule behält sich vor, ohne Ankündigung Änderungen oder Ergänzungen der bereitgestellten Informationen oder Daten vorzunehmen. Für externe Links übernehmen wir keine Haftung. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.</p>
                <p>Informationen zum Copyright: Der Inhalt dieser Webseite ist urheberrechtlich geschützt. Alle Abbildungen und fotografischen Bilder auf der Webseite dürfen nicht ohne Genehmigung übernommen, vervielfältigt und verbreitet werden.</p>
                <p>Konzept und Gestaltung der Website:<br /><a rel="noreferrer" href='https://www.bjoernschmidt.info' target="_blank">Björn Schmidt MA</a> und <a rel="noreferrer" href="https://annesteinhagen.com" target="_blank">Anne Steinhagen MFA</a></p>
                <p>Realisation:<br /><a rel="noreferrer" href='https://www.bjoernschmidt.info' target="_blank">Björn Schmidt</a></p>
                <p>Fonts in use:<br />»FH Cordelia« by Fatih Hadal of <a rel="noreferrer" href="https://hardalstudio.com/" target="_blank" >Hardal Studio</a><br />»Arnold« by <a rel="noreferrer" href="https://www.philippneumeyer.com" target="_blank">Phillip Caroline Neumeyer</a></p>
                <p>Fotos auf dieser Seite / deren Rechte liegen bei: Kaja Grope MA, Anne Steinhagen MFA, Sung-Ho Jo MFA, Constantin Schröder MFA, Seoyeon Choi MFA, Arturo Sayan MFA, Laura Carlotta Cordt MFA, Mara Scholz MFA</p>
                <p>Content Management System: <a rel="noreferrer" href='https://www.sanity.io' target="_blank">Sanity.io</a><br />Built with <a rel="noreferrer" href='https://www.gatsbyjs.com' target="_blank">Gatsby.js</a></p>
                <p>Es gilt die Datenschutzerklärung der Muthesius Kunsthochschule<br /><a rel="noreferrer" href="https://muthesius-kunsthochschule.de/impressum/" target="_blank">https://muthesius-kunsthochschule.de/impressum/</a></p>
            </section>
        </div>
    )
}