import React, { useEffect, useState, Children } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Navigation from "../components/Navigation"
import Accordion from "../components/Accordion"
import Info from "../components/Info"
// import SEO from "../components/seo"
import useWindowSize from "../hooks/useWindowSize"
import useTouch from "../hooks/useTouch"
import {RemoveScroll} from 'react-remove-scroll'
import SimpleReactLightbox from "simple-react-lightbox";


export default function Layout(props) {
  const data = useStaticQuery(
    graphql`
      query {
        allSanityArtist(filter: {participance: {eq: true}},
          sort: { fields: [lastName, firstName], order: [ASC, ASC] }
        ) {
          edges {
            node {
              id
              firstName
              lastName
              field {
                fieldNameGer
              }
              graduationDate(formatString: "YYYY")
              previewImage {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                  localFile {
                    childImageSharp {
                      fixed(width: 500, fit: INSIDE, height: 500) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
              slug {
                current
              }
            }
          }
        }
      }
    `
  )

  const allData = data.allSanityArtist.edges
  const navState = ["inactive", "inactive", "active"]
  const artistState = ["inactive", "active", "inactive"]
  // const homeState = ["active", "inactive", "inactive"]
  const location = props.location.pathname
  // var fromLocation = props.location.state.fromLocation;
  const initState = location === "/" ? navState : artistState
  // const initState = location !== "/" ? artistState : fromLocation == 'fromArtist' ? homeState : navState
  // const initState = location === "/" ? (fromLocation === "fromArtist" ? homeState : navState) : artistState
  // console.log(initState)
  

  const { width } = useWindowSize()
  const isTouch = useTouch()

  const [accordionState, setAccordionState] = useState(initState)
  const [preview, setPreview] = useState(Array(allData.length).fill(false))
  const [position, setPosition] = useState({
    xPosition: 0,
    yPosition: 0,
  })

  function toggleAccordion(index) {
    const arrayAccordion = Array(3).fill("inactive")
    arrayAccordion[index] = "active"
    setAccordionState(arrayAccordion)
    const arrayPreview = Array(allData.length).fill(false)
    setPreview(arrayPreview)
    setPosition({
      xPosition: -250,
      yPosition: -250,
    })
  }

  function handleMouseEnter(index) {
    const array = Array(allData.length).fill(false)
    array[index] = true
    setPreview(array)
  }

  function handleMouseLeave(index) {
    const array = Array(allData.length).fill(false)
    setPreview(array)
  }

  function handleMouseMove(e) {
    const newPosition = {
      xPosition: e.nativeEvent.clientX,
      yPosition: e.nativeEvent.clientY,
    }
    setPosition(newPosition)
  }

  let scrollTarget = null;
  let currentScrollTarget = null;

  function handleScroll(event) {
  const array = Array(allData.length).fill(false)
  const scrollTop = event.target.scrollTop
  scrollTarget = Math.floor((64 + scrollTop) / 128)
  if (currentScrollTarget !== scrollTarget) {
    array[scrollTarget] = true
    setPreview(array)
    currentScrollTarget = scrollTarget
    }
    console.log(scrollTop)
    console.log(scrollTarget)
  }   
  
  useEffect(() => {
    // setPreview(Array(allData.length).fill(false))
    window.scrollTo(0,0)
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
      window.scrollTo(0,0)
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    
  }, []);


  const children = props.children
  

  return (
    <div>
      <RemoveScroll>
      <SimpleReactLightbox>
    <div className="layout">
      {/* <SEO/> */}
        <Accordion
          index={0}
          titleTop="M"
          titleToggle="+"
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
          onClick={() => toggleAccordion(0)}
          state={accordionState[0]}
          onScroll={() => {}}
        >
          <Info />
        </Accordion>

        <Accordion
          index={1}
          titleTop="F"
          titleToggle="+"
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
          onClick={() => toggleAccordion(1)}
          state={accordionState[1]}
          onScroll={() => {}}
        >
          {/* {props.children} */}
          {Children.map(children, child => React.cloneElement(child, { onClick: () => toggleAccordion(1) }))}
        </Accordion>

        <Accordion
          index={2}
          titleTop="A"
          titleToggle="+"
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
          onClick={() => toggleAccordion(2)}
          state={accordionState[2]}
          // onScroll={isTouch ? handleScroll : () => { return false; }}
          onScroll={isTouch ? (width < 768 ? handleScroll : () => { return false; }) : () => { return false; }}
        >
          <Navigation 
          data={allData}
          preview={preview}
          position={position}
          location={location}
          onClick={() => toggleAccordion(1)} 
          onMouseMove={handleMouseMove}
          onMouseEnter={isTouch ? () => { return false; } : handleMouseEnter} // prevent sticky preview-image on touch
          onMouseLeave={isTouch ? () => { return false; } : handleMouseLeave} // prevent sticky preview-image on touch
          />
        </Accordion>
    </div>
    </SimpleReactLightbox>
    </RemoveScroll>
    </div>
  )
}
