import React from "react"
import { navigate } from "gatsby"

export default function Artist(props) {
  const slug = "/" + props.slug
  const slugWithSlash = slug + "/"

  // console.log(props.location)
  // console.log(props.slug)

  return (
    <div
      role="button"
      tabIndex={props.index}
      onMouseEnter={() => props.onMouseEnter()}
      onMouseLeave={() => props.onMouseLeave()}
      onClick={() => {
        navigate(slug)
        // props.onMouseLeave()
        if (props.location === slug || props.location === slugWithSlash) {
          props.onClick()
        }
        // props.onClick()        
      }}
      onKeyDown={() => {
        navigate(slug)
        props.onMouseLeave()
        props.onClick()        
      }}
      className={props.preview[props.index] ? "gridElement__active" : "gridElement"}
    >
      <ul>
        <li>{props.firstName}</li>
        <li>{props.lastName}</li>
      </ul>
    </div>
  )
}
