import React from "react"
import ArtistList from "../components/ArtistList"
import PreviewWrapper from "../components/PreviewWrapper"

export default function Navigation(props) {

    return (
      <div className="navigation"  role="navigation"
      onMouseMove={(e) => props.onMouseMove(e)}
      >
        <ArtistList
          data={props.data}
          onMouseEnter={(index) => props.onMouseEnter(index)}
          onMouseLeave={(index) => props.onMouseLeave(index)}
          onClick={() => props.onClick()}
          preview={props.preview}
          location={props.location}
       />
        <PreviewWrapper
          xPos={props.position.xPosition}
          yPos={props.position.yPosition}
          data={props.data}
          preview={props.preview}
       />
      </div>
  )
}
